

.logo
{
    cursor: pointer;
    margin:auto;
}

.logodiv img
{
    margin:auto;
}

.logodiv
{
     height: 180vh;  
     display: flex;  
}

.submitbtn {    
    color: #fff;
    background-color: rgba(0, 0, 0, 0.87);
    margin-top: 20px;
}

.btn 
{
  margin-left: 16px;
  margin-top:10;
  background-color:#702F8A;
}


.errorMessage {
    color: red;
    font-size:12px;
    font-weight: bold;
}

.hubgraph {
    padding:10px;
}

.timegraph {
    min-height:300px;
    width:500px;
    padding:10px;
}

.pad20 {
    padding:20px;
}

.min-height {
    min-height:400px
}